import { createContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { handleDefaultError } from '../utils/handleDefaultError';
import PropTypes from 'prop-types';
import firebase from '../lib/firebase';
import dotenv from 'dotenv';
dotenv.config();

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userRole: null,
};

const reducer = (state, action) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { user, isAuthenticated, userRole } = action.payload;

    return {
      ...state,
      isAuthenticated: isAuthenticated,
      isInitialized: true,
      user,
      userRole,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  platform: 'Firebase',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
});

export const AuthProvider = props => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentUserData = JSON.parse(localStorage.getItem('userData'));
  const [authData, setAuthData] = useState(currentUserData);
  const [role, setRole] = useState(null);

  const setData = (data, idToken) => {
    localStorage.setItem('userData', JSON.stringify(data));
    // localStorage.setItem('IdToken', idToken);
    setAuthData(data);
  };

  // Using a direct API call as implementing the `useGetOrganisationQuery`
  // RTK-Query hook causes an error.
  const getOrg = async (orgId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SPACES_API}admin/organisations/${orgId}`
      );
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (err) {
      handleDefaultError(err);
    }
  };

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          let userRole;
          let userOrg;

          const idTokenResult = await firebase
            .auth()
            .currentUser.getIdTokenResult();

          // set user role
          userRole = idTokenResult.claims.role;
          userOrg = idTokenResult.claims?.orgId;
          setRole(userRole);

          // Get organisation data (e.g orgType) via API call.
          const org = await getOrg(userOrg);

          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
                name: user?.displayName,
                userRole,
                userOrg,
                organisation: org,
              },
              
              userRole,
            },
          });
          setData(
            {
              id: user.uid,
              avatar: user.photoURL,
              email: user.email,
              name: user?.displayName,
              userRole,
              userOrg,
              organisation: org,
            },
            idTokenResult.token
          );
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [dispatch]
  );

  const signInWithEmailAndPassword = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => {
    const auth = firebase.auth();
    const provider = new firebase.auth.GoogleAuthProvider();

    auth
      .signInWithPopup(provider)
      .then(res => {
        return res.user.getIdToken();
      })
      .catch(error => {});
  };

  const createUserWithEmailAndPassword = async (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const sendPasswordResetEmail = async email => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const logout = async () => {
    await firebase.auth().signOut();
    localStorage.removeItem('userData');
    // localStorage.removeItem('IdToken');
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout,
        sendPasswordResetEmail,
        authData,
        userRole: role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
