// Helper function: transforms schema v1 to schema v2 if needed.
export const categoryTransform = preferences => {
  // Support v3 schemas.
  if (preferences.category) return preferences.category;
  if (preferences.groupType) return preferences.groupType;

  // Support v2 schema.
  if (preferences.type) return preferences.type;

  // v1 schema. Still in use, to be depreciated.
  if (preferences.gasOnly === 'true' || preferences.gasOnly === true) {
    return 'gasOnly';
  }
  if (
    preferences.electricityOnly === 'true' ||
    preferences.electricityOnly === true
  ) {
    return 'electricityOnly';
  }
  if (preferences.dualFuel) {
    return 'dualFuel';
  }
  return 'unknown';
};
