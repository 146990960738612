import HomeboxDarkLogo from '../../assets/homebox-dark.png';
import HomeboxLogo from '../../assets/homebox-logo-transparent.png';

const Logo = ({ dark }) => {
  return (
    <div
      style={{
        width: '180px',
        height: '35px',
      }}
    >
      <img
        src={dark ? HomeboxDarkLogo : HomeboxLogo}
        width='100%'
        alt='homebox logo'
      />
    </div>
  );
};
export default Logo;
