import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import merge from 'lodash/merge';
import clone from 'lodash/cloneDeep';
import { handleDefaultError } from '../utils/handleDefaultError';
axios.defaults.baseURL = process.env.REACT_APP_SPACES_API;

const initialState = {};

const slice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    getSelectedSpace(state, action) {
      return action.payload;
    },
  },
});

export const { reducer } = slice;

export const getSpace = spaceId => async dispatch => {
  try {
    const response = await axios.get(`/spaces/${spaceId}`);
    if (response.status === 200) {
      return dispatch(slice.actions.getSelectedSpace(response.data));
    }
  } catch (err) {
    handleDefaultError(err);
    console.error(err);
  }
};

export const spacesServiceUpdate =
  (serviceIndex, update) => async (dispatch, getState) => {
    try {
      const currentState = getState();
      let spaces = clone(currentState.spaces);
      let service = spaces?.services?.[serviceIndex];

      const updatedService = merge({}, service, update);

      spaces.services[serviceIndex] = updatedService;

      return dispatch(slice.actions.getSelectedSpace(spaces));
    } catch (err) {
      console.error(err);
    }
  };

// spaceId, serviceId & switchId are included here because we need to keep the signature the same as it is across other apps. This way when we pass spacesSwitchesUpdate() through to the AddressFinder it's the same interface.
export const spacesSwitchesUpdate =
  (spaceId, serviceId, switchId, serviceIndex, switchIndex, update) =>
  async (dispatch, getState) => {
    try {
      const currentState = getState();
      const spaces = clone(currentState.spaces);

      // get current switch object
      const currentSwitch = spaces.services[serviceIndex].switches[switchIndex];

      // merge switch objects using  _.merge
      const updatedSwitch = merge({}, currentSwitch, update);
      spaces.services[serviceIndex].switches[switchIndex] = updatedSwitch;

      // delete issues if needed.
      if (update.issues.length !== currentSwitch.issues.length) {
        spaces.services[serviceIndex].switches[switchIndex].issues =
          update.issues;
      }

      // hack: delete `null` value to avoid server-side validation issues.
      if (update.submittedData?.myHome?.address?.region === null) {
        delete spaces.services[serviceIndex].switches[switchIndex].submittedData
          .myHome.address.region;
      }

      return dispatch(slice.actions.getSelectedSpace(spaces));
    } catch (err) {
      console.error(err);
    }
  };

// Update user account details and property details.
export const accountUpdate = update => async (dispatch, getState) => {
  try {
    const currentState = getState();
    let spaces = clone(currentState.spaces);
    let user = spaces?.user;

    const updatedUser = merge({}, user, update);

    spaces.user = updatedUser;

    return dispatch(slice.actions.getSelectedSpace(spaces));
  } catch (err) {
    console.error(err);
  }
};

export default slice;
