import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Error401Dark from '../../assets/svgs/error401_dark.svg';
import Error401Light from '../../assets/svgs/error401_light.svg';

const Unauthorized = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        <title>
          Error: Unauthorized to view page | Homebox Management Dashboard
        </title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px',
        }}
      >
        <Container maxWidth='lg'>
          <Typography
            align='center'
            color='textPrimary'
            variant={mobileDevice ? 'h4' : 'h1'}
          >
            Permission Denied
          </Typography>
          <Typography
            align='center'
            color='textSecondary'
            sx={{ mt: 0.5 }}
            variant='subtitle2'
          >
            You don't have the required persmissions to view this page. Please
            Contact Homebox Administrator.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Box
              alt='Under development'
              component='img'
              src={theme.palette.mode === 'dark' ? Error401Dark : Error401Light}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Button
              color='primary'
              component={RouterLink}
              to='/dashboard'
              variant='outlined'
            >
              Back to Home
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Unauthorized;
