import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_SPACES_API;

const initialState = {
  externalRef: null,
};

const slice = createSlice({
  name: 'selectedSpaces',
  initialState,
  reducers: {
    updateExternalRef(state, action) {
      state.externalRef = action?.payload;
    },
  },
});

export const { reducer } = slice;

export const updateExternalRef = value => async dispatch => {
  return dispatch(slice.actions.updateExternalRef(value));
};

export default slice;
