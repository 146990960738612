import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  // Link,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
import BriefcaseIcon from '../../icons/Briefcase';
// import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
// import ShareIcon from '../../icons/Share';
// import HomeIcon from "../../icons/Home";
import ArchiveCartIcon from '../../icons/Archive';
// import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { useGetOrganisationQuery } from '../../slices/organisations';

const sections = [
  {
    title: 'Reports',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize='small' />,
        roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
      },
      // {
      //   title: 'Analytics',
      //   path: '/dashboard/analytics',
      //   icon: <ChartPieIcon fontSize='small' />,
      //   roles: ['superadmin', 'platformAdmin', 'admin'],
      // },
      // {
      //   title: 'Account',
      //   path: '/dashboard/account',
      //   icon: <UserIcon fontSize='small' />,
      //   roles: ['superadmin', 'platformAdmin'],
      // },
    ],
    roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Users',
        path: '/dashboard/users',
        icon: <UsersIcon fontSize='small' />,
        children: [
          {
            title: 'Dashboard Users',
            path: '/dashboard/users',
            roles: ['superadmin', 'platformAdmin'],
          },
        ],
        roles: ['superadmin', 'platformAdmin'],
      },
      {
        title: 'Properties',
        path: '/dashboard/properties',
        icon: <ArchiveCartIcon fontSize='small' />,
        children: [
          {
            title: 'All Properties',
            path: '/dashboard/properties',
            roles: ['superadmin', 'platformAdmin'],
          },
        ],
        roles: ['superadmin', 'platformAdmin'],
      },
      {
        title: 'Switches',
        icon: <FolderOpenIcon fontSize='small' />,
        path: '/dashboard/switches',
        roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
        children: [
          {
            title: 'Energy Switches',
            path: '/dashboard/switches/energy',
            roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
          },
          {
            title: 'Broadband Switches',
            path: '/dashboard/switches/broadband',
            roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
          },
        ],
      },
      // {
      //   title: "Addresses",
      //   path: "/dashboard/addresses",
      //   icon: <HomeIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: "All Addresses",
      //       path: "/dashboard/addresses",
      //       roles: ["superadmin"]
      //     },
      //   ],
      //   roles: ["superadmin"]
      // },
      {
        title: 'Payouts',
        path: '/dashboard/payouts',
        icon: <ReceiptIcon fontSize='small' />,
        children: [
          {
            title: 'All Payouts',
            path: '/dashboard/payouts',
            roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
          },
        ],
        roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
      },
    ],
    roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
  },
  {
    title: 'Tools',
    items: [
      {
        title: 'API Config',
        path: '/dashboard/api/browse',
        icon: <BriefcaseIcon fontSize='small' />,
        // children: [
        //   {
        //     title: 'Browse',
        //     path: '/dashboard/api/browse',
        //     roles: ['superadmin', 'platformAdmin', 'admin'],
        //   },
        // ],
        roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
      },
      {
        title: 'Widget Config',
        path: '/dashboard/widget',
        icon: <FolderOpenIcon fontSize='small' />,
        roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
      },
    ],
    roles: ['superadmin', 'platformAdmin', 'admin', 'manager'],
  },
  // {
  //   title: 'Products',
  //   items: [
  //     {
  //       title: 'Products',
  //       path: '/dashboard/products',
  //       icon: <ClipboardListIcon fontSize='small' />,
  //       roles: ['superadmin', 'platformAdmin'],
  //     },
  //     {
  //       title: 'Suppliers',
  //       path: '/dashboard/suppliers',
  //       icon: <ShareIcon fontSize='small' />,
  //       roles: ['superadmin', 'platformAdmin'],
  //     },
  //   ],
  //   roles: ['superadmin', 'platformAdmin'],
  // },
];

const DashboardSidebar = props => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  const lgUp = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const organisation = useGetOrganisationQuery({ orgId: user.userOrg });

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to='/'>
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to='/dashboard'>
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color='textPrimary' variant='subtitle2'>
                {user.name ?? user.email}
              </Typography>
              <Typography color='textSecondary' variant='body2'>
                <>{organisation?.data?.data?.name}</>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map(section => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color='textPrimary' variant='subtitle2'>
            Need Help?
          </Typography>
          <Typography color='textSecondary' variant='body2'>
            <a
              href='http://developers.homebox.co.uk/'
              target='_blank'
              rel='noreferrer'
            >
              Check our docs
            </a>
          </Typography>
          <Button
            color='primary'
            fullWidth
            sx={{ mt: 2 }}
            href='mailto:hi@homebox.co.uk?subject="Partner support"'
            variant='contained'
          >
            Speak to Homebox
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant='permanent'
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor='left'
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant='temporary'
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
