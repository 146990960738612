import dotenv from 'dotenv';
import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseConfig } from '../config';
dotenv.config();

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default firebase;
