import toast from 'react-hot-toast';

/**
 * @description handles network errors and error with statuses >= 400
 *
 * @param {object} err
 */

export const handleDefaultError = err => {
  if (err.message && err.message.indexOf('Network Error') !== -1) {
    return toast.error(
      'Network Error, please check your internet connection and try again'
    );
  }
  if (err?.response?.status >= 400) {
    return toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }

  if (
    err?.response?.data?.message?.toLowerCase().includes('Firebase ID token has expired.') ||
    err?.response?.data?.message?.toLowerCase().includes('Decoding Firebase ID token failed.')
  ) {
    return toast.error('Your session has expired, please refresh the page.');
  }

  return toast.error(`Sorry, there was an error ${err}`);
};
