import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from '@material-ui/core';
import LoginFirebase from '../../components/authentication/LoginWithFirebase';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';

const Login = () => {
  const { platform } = useAuth();

  return (
    <>
      <Helmet>
        <title>Login | HomeBox Management Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth='sm' sx={{ py: '40px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to='/'>
              <Logo
                dark
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography color='textPrimary' gutterBottom variant='h4'>
                    Log in
                  </Typography>
                  <Typography color='textSecondary' variant='body2'>
                    Log in on the dashboard
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                {platform === 'Firebase' && <LoginFirebase />}
              </Box>
              <Divider sx={{ my: 3 }} />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <Link
                  color='textSecondary'
                  component={RouterLink}
                  to='/register'
                  variant='body2'
                >
                  Create new account
                </Link>
                <Link
                  color='textSecondary'
                  component={RouterLink}
                  to='/password-reset'
                  variant='body2'
                >
                  Forgot Password?
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
