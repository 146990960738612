import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import AdminRoleGuard from './components/RoleGuard';
import DashboardLayout from './components/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import ComingSoon from './components/ComingSoon';

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Overview = Loadable(lazy(() => import('./pages/Dashboard')));
const Switches = Loadable(lazy(() => import('./pages/Switches')));
const SwitchEdit = Loadable(lazy(() => import('./pages/Switches/SwitchEdit')));
const SwitchDetails = Loadable(
  lazy(() => import('./pages/Switches/SwitchDetails'))
);
const Users = Loadable(lazy(() => import('./pages/Users')));
const Login = Loadable(lazy(() => import('./pages/Login')));
const Register = Loadable(lazy(() => import('./pages/Register')));
const PasswordReset = Loadable(lazy(() => import('./pages/PasswordReset')));
const Settings = Loadable(lazy(() => import('./pages/Settings')));

const routes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: '/register',
    element: (
      <GuestGuard>
        <Register />
      </GuestGuard>
    ),
  },
  {
    path: '/password-reset',
    element: (
      <GuestGuard>
        <PasswordReset />
      </GuestGuard>
    ),
  },
  {
    path: 'dashboard/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'analytics',
        element: <ComingSoon />,
      },
      {
        path: 'account',
        element: <ComingSoon />,
      },
      {
        path: 'properties',
        element: <ComingSoon />,
      },
      {
        path: 'addresses',
        element: <ComingSoon />,
      },
      {
        path: 'payouts',
        element: <ComingSoon />,
      },
      {
        path: 'widget',
        element: <ComingSoon />,
      },
      {
        path: 'api/browse',
        element: <ComingSoon />,
      },
      {
        path: 'products',
        element: <ComingSoon />,
      },
      {
        path: 'suppliers',
        element: <ComingSoon />,
      },
      // All switches (not widely used).
      {
        path: 'switches',
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <Switches basepath='/dashboard/switches' />
              </AuthGuard>
            ),
          },
          {
            path: ':spaceId/:serviceId/:switchId/edit',
            element: (
              <AdminRoleGuard>
                <SwitchEdit basepath='/dashboard/switches' />
              </AdminRoleGuard>
            ),
          },
          {
            path: ':spaceId/:serviceId/:switchId/details',
            element: (
              <AuthGuard>
                <SwitchDetails basepath='/dashboard/switches' />
              </AuthGuard>
            ),
          },
        ],
      },
      // Energy switches view
      {
        path: 'switches/energy',
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <Switches
                  basepath='/dashboard/switches/energy'
                  key='switch-energy'
                  options={{ serviceType: 'energy' }}
                />
              </AuthGuard>
            ),
          },
          {
            path: ':spaceId/:serviceId/:switchId/edit',
            element: (
              <AdminRoleGuard>
                <SwitchEdit basepath='/dashboard/switches/energy' />
              </AdminRoleGuard>
            ),
          },
          {
            path: ':spaceId/:serviceId/:switchId/details',
            element: (
              <AuthGuard>
                <SwitchDetails basepath='/dashboard/switches/energy' />
              </AuthGuard>
            ),
          },
        ],
      },
      // Broadband switches view
      {
        path: 'switches/broadband',
        children: [
          {
            path: '',
            element: (
              <AuthGuard>
                <Switches
                  basepath='/dashboard/switches/broadband'
                  key='switch-broadband'
                  options={{ serviceType: 'broadband' }}
                />
              </AuthGuard>
            ),
          },
          {
            path: ':spaceId/:serviceId/:switchId/edit',
            element: (
              <AdminRoleGuard>
                <SwitchEdit basepath='/dashboard/switches/broadband' />
              </AdminRoleGuard>
            ),
          },
          {
            path: ':spaceId/:serviceId/:switchId/details',
            element: (
              <AuthGuard>
                <SwitchDetails basepath='/dashboard/switches/broadband' />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            element: (
              <AdminRoleGuard>
                <Users />
              </AdminRoleGuard>
            ),
          },
        ],
      },
    ],
  },
];

export default routes;
