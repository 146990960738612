import PropTypes from 'prop-types';
import Unauthorized from '../Unauthorized';
import useAuth from '../../hooks/useAuth';

const AdminRoleGuard = ({ children }) => {
  const { user } = useAuth();

  if (user.userRole !== 'superadmin' && user.userRole !== 'platformAdmin') {
    return <Unauthorized />;
  }

  return <>{children}</>;
};

AdminRoleGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminRoleGuard;
