import { configureStore } from '@reduxjs/toolkit';
import selectedSpaces from '../slices/selectedSpace';
import spaces from '../slices/space';
import { apiSlice } from '../slices/organisations';

export default configureStore({
  reducer: {
    selectedSpaces: selectedSpaces.reducer,
    spaces: spaces.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
