import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import useAuth from './hooks/useAuth';
import SplashScreen from './components/SplashScreen';
import routes from './routes';
import { createCustomTheme } from './theme';
import { useNavigate } from 'react-router-dom';
import firebase from './lib/firebase';
import axios from 'axios';

// Set default API base URL to the spaces API.
axios.defaults.baseURL = process.env.REACT_APP_SPACES_API;

// Set auth token in header across entire app.
axios.interceptors.request.use(async config => {
  // getIdToken() checks if the access/ID token is expired.
  // If it is, it uses the refresh token to get a new ID token,
  // otherwise it uses the ID token from a local cache.
  const token = await firebase.auth().currentUser.getIdToken();
  config.headers = {
    Authorization: `Bearer ${token}`,
  };
  return config
}, (error) => {
  return Promise.reject(error)
})

export const Navigate = ({ url }) => {
  let nav = useNavigate();
  nav(url || '/');

  return null;
};

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position='top-center' />
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
