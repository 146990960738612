import firebase from '../lib/firebase';
import Label from '../components/Label';
import { categoryTransform } from './categoryTransform';

export const capitalizeFirst = str => {
  if (str) {
    let al = str.slice(1, str.length);
    return str.charAt(0).toUpperCase() + al;
  }
  return str;
};

export const issueOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Address confirmation (by user)',
    value: 'confirm-address',
    description:
      "Confirmation is needed of the address as it's unclear if it's been entered correctly",
  },
  {
    label: 'Address verification (by team)',
    value: 'address-verification-manual',
    description:
      'A manual verification attempt is needed for the address as it could not be auto-matched',
  },
  {
    label: 'Address auto-verification needed',
    value: 'address-verification-auto',
    description:
      'An automatic address verification attempt is needed to match electricity and/or gas meters and/or full address record as only a freetext address is present',
  },
  {
    label: 'Bank details invalid',
    value: 'bank-details-invalid',
    description: 'The bank details were invalid and may have failed our checks',
  },
  {
    label: 'MPAN already on supply',
    value: 'mpan-already-on-supply',
    description: 'The electricity meter is already on supply with the supplier',
  },
  {
    label: 'Switch rejected - wrong product code',
    value: 'rebel-rejection-wrong-product-code',
    description: 'The product code is not valid for Rebel Energy',
  },
  {
    label: 'Credit check failed',
    value: 'credit-check-failed',
    description: 'The customer failed a credit check a supplier has in place',
  },
  {
    label: 'Duplicate',
    value: 'duplicate',
    description: 'The switch appears to be a duplicate',
  },
  {
    label: 'Email invalid or bounced',
    value: 'email-invalid',
    description: 'The email is invalid or we received a bounceback',
  },
  {
    label: 'Expired product',
    value: 'expired-product',
    description:
      'The product the customer switched to is no longer available. The originally estimated price is no longer valid. Request the customer to submit their details again in order to see the latest products.',
  },
  {
    label: 'Meter issue',
    value: 'meter-issue',
    description:
      'There is an general issue with the meter not covered by another issue status, e.g marked as de-energised',
  },
  {
    label: 'Meter unregistered',
    value: 'meter-unregistered',
    description:
      'The meter does not appear to be registered in the national databases',
  },
  {
    label: 'Meter type unsupported',
    value: 'meter-type-unsupported',
    description:
      'This meter type is not support by this supplier for the product, e.g prepayment meter, business meter. This could be a data issue in the national databases that needs to be fixed. Confirm with customer and request with current or new supplier that the issue is fixed.',
  },
  {
    label: 'MPAN not found',
    value: 'mpan-not-found',
    description:
      'The electricity meter could not be located for the address or the electricity meter MPAN is missing',
  },
  {
    label: 'MPRN not found',
    value: 'mprn-not-found',
    description:
      'The gas meter could not be located for the address or the gas meter MPRN is missing',
  },
  {
    label: 'MPAN & MPRN not found',
    value: 'mpan-mprn-not-found',
    description:
      'The electricity and gas meters could both not be located for the address',
  },
  {
    label: 'Usage check needed',
    value: 'usage-check-needed',
    description:
      'Usage seems to be unusually high or low, double check with the customer that the usage is correct before proceeding',
  },
  {
    label: 'Other',
    value: 'other',
    description: 'See switch notes for more detail',
  },
];

export const issueStatusOptions = [
  {
    label: 'All',
    value: 'all',
    color: 'muted',
  },
  {
    label: 'Live',
    value: 'live',
    color: 'warning',
  },
  {
    label: 'Resolved',
    value: 'resolved',
    color: 'success',
  },
  {
    label: 'Contacted Customer',
    value: 'contacted-customer',
    color: 'primary',
  },
  {
    label: 'Unresolvable',
    value: 'unresolvable',
    color: 'muted',
  },
];

// Helper function to generate a label for an issue with the background colour reflecting the current status of the issue.
export const getIssueStatusLabel = (issue, status) => {
  const issueObj = issueOptions.find(el => el.value === issue) || {
    label: issue,
    value: issue,
  };
  const statusObj = issueStatusOptions.find(el => el.value === status) || {
    color: 'muted',
    label: status,
    value: status,
  };

  return <Label color={statusObj?.color}>{issueObj?.label}</Label> || '';
};

// usage: const category = energyCategories.find(el => el.value === categoryName);
export const energyCategories = [
  {
    label: 'Unknown',
    value: '',
  },
  {
    label: 'Dual Fuel',
    value: 'dualFuel',
  },
  {
    label: 'Electricity Only',
    value: 'electricityOnly',
  },
  {
    label: 'Gas Only',
    value: 'gasOnly',
  },
];

export const getEnergyCategory = productDetails => {
  if (!productDetails) return '';

  let categoryName = productDetails.category;
  if (!categoryName) {
    categoryName = categoryTransform(productDetails);
  }
  const category = energyCategories.find(el => el.value === categoryName);
  return (category && category.label) || '';
};

// See here for status descriptions: https://developers.homebox.co.uk/docs/api-docs/88b2baa6e3118-statuses
export const switchStatuses = [
  {
    label: 'Selected',
    value: 'selected',
    color: 'muted',
  },
  {
    label: 'Pending (Submitted)',
    value: 'submitted',
    color: 'muted',
  },
  {
    label: 'Pending (Submitted)',
    value: 'submittedUser',
    color: 'muted',
  },
  {
    label: 'Pending',
    value: 'pending',
    color: 'muted',
  },
  {
    label: 'Paused',
    value: 'paused',
    color: 'warning',
  },
  {
    label: 'In Progress',
    value: 'submittedSupplier',
    color: 'notice',
  },
  {
    label: 'In Progress (Re-registration)',
    value: 'submittedReegistration',
    color: 'notice',
  },
  {
    label: 'Completed',
    value: 'completed',
    color: 'success',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
    color: 'error',
  },
  {
    label: 'Duplicate',
    value: 'duplicate',
    color: 'muted',
  },
  {
    label: 'Failed',
    value: 'failed',
    color: 'error',
  },
  {
    label: 'Failed (pre submission)',
    value: 'failed-pre',
    color: 'error',
  },
  {
    label: 'Failed (post submission)',
    value: 'failed-post',
    color: 'error',
  },
  {
    label: 'Stopped',
    value: 'stopped',
    color: 'error',
  },
  {
    label: 'Unknown',
    value: 'unknown',
    color: 'muted',
  },
];

// Helper function to generate label for switch status.
export const getSwitchStatusLabel = key => {
  const label = switchStatuses.find(el => el.value === key) || {
    label: key,
    color: 'muted',
  };
  return <Label color={label?.color}>{label?.label}</Label> || '';
};

export const isAdmin = role => {
  return role === 'superadmin' || role === 'platformAdmin';
};

/**
 * @function getUserToken
 *
 * @description only refreshes the token when the current token has expired! So it doesn't make extra api calls/requests to firebase
 *
 * */
//  Firebase ID token has expired. Get a fresh ID token from your client app and try again (auth/id-token-expired). See https://firebase.google.com/docs/auth/admin/verify-id-tokens for details on how to retrieve an ID token.
export const handleTokenRefresh = async () => {
  try {
    const idToken = await firebase.auth()?.currentUser?.getIdToken(false);
    return idToken;
  } catch (error) {
    if (error) {
      console.error(error);
    }
  }
};
