// Firebase config.
const firebasekeybase64 = process.env.REACT_APP_FIREBASE_WEB_BASE64;
let firebaseConfig = {};
try {
  firebaseConfig = JSON.parse(
    Buffer.from(firebasekeybase64, 'base64').toString('utf-8')
  );
} catch (e) {
  console.error(e);
}

export { firebaseConfig };
